let str =
    '欢迎使用“UitaUita”提供的产品和服务。<br />' +
    'UitaUita（或简称“我们”）深知个人信息对您的重要性，我们一向承诺保护使用我们的产品和服务（以下统称“UitaUita服务”）之用户（以下统称“用户”或“您”）的个人信息及隐私安全。<br />' +
    '您在使用UitaUita服务时，我们可能会收集和使用您的相关个人信息。我们希望通过《UitaUita隐私政策》（以下简称“本政策”）向您说明我们在收集和使用您相关个人信息时对应的处理规则，以及我们为您提供的访问、更正、删除和保护这些个人信息的方式，以便更好的保障您的权益。<br />' +
    '<br />' +
    '【特别提示】还请您在使用UitaUita服务前，仔细阅读（未成年人请在监护人陪同下阅读）并了解本政策（特别是加粗或下划线标注的内容），以做出适当选择。一旦您使用或在我们更新本政策后继续使用我们的产品或服务，即意味着您同意本政策并同意我们按照本政策处理您的相关个人信息。<br />' +
    '<br />' +
    '一、我们如何收集和使用您的个人信息<br />' +
    '我们会出于本政策所述的以下目的，收集和使用您的个人信息：<br />' +
    '（一）帮助您完成注册<br />' +
    '为完成创建账号，您需要提供以下信息：本人的手机号码。如果您仅需使用浏览、搜索等基本服务，您不需要创建账号及提供上述信息。在注册过程中，如果您提供以下 额外信息，将有助于我们给您提供个性化的商品推荐和更优的购物体验：您的昵称、性别、出生年月日、头像、感兴趣的类别以及您的实名认证等相关信息。但如果您不提供这些信息，并不会影响您使用UitaUita服务的基本功能。<br />' +
    '<br />' +
    '（二）向您提供商品或服务<br />' +
    '1、您向我们提供的信息<br />' +
    '预定或下单：当您准备预定或下单购买UitaUita网站的商品或服务时，您至少需要向我们提供收货人姓名、收货地址、邮政编码及收货人联系电话； 您在UitaUita网站上购买跨境商品时，您还可能需要根据国家法律法规或服务提供方的要求提供您的实名信息，这些实名信息可能包括您的个人身份信息（如身份证、护照、驾驶证、社保卡、居住证）、个人生物识别信息（如指纹、声纹、掌纹、虹膜、面部识别特征）等个人敏感信息。<br />' +
    '您可以通过UitaUita为其他人订购商品或服务，您需要提供该实际订购人的前述个人信息。 向UitaUita提供该实际订购人的前述个人信息之前，您需确保您已经取得其授权同意。<br />' +
    '支付结算：在您预定或下单后，您可以选择我们的关联方或与我们合作的第三方支付机构（包括微信支付，以下称“支付机构”）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的UitaUita订单号与交易金额信息同这些支付机构共享以实现其确认您的支付指令并完成支付。<br />' +
    '交付产品或服务：在当您下单并选择在线完成支付后，我们的关联方或与我们合作的第三方配送公司（包括顺丰、EMS、韵达、USPS等，以下称“配送公司”）将为您完成订单的交付。 您知晓并同意我们的关联方或与我们合作的第三方配送公司会在上述环节内使用您的订单信息以保证您的订购的商品能够安全送达。如果您拒绝共享此类信息，我们将无法完成相关交付服务。<br />' +
    '配送公司的配送员在为您提供配送服务的同时，基于某些业务法律上要求实名认证的需求，会协助您完成实名认证。我们并没有收集您的身份证信息，且我们或配送公司的配送员均须遵守公司保密制度的规定。<br />' +
    '售后服务：当您与我们的客服取得联系时，我们的系统可能会记录您与客服之间的通讯记录，以及使用您的账号信息和订单信息以便核验身份；当您需要客服协助您修改有关信息（如配送地址、联系方式等）时，您可能还需要提供上述信息外的其他信息以便完成修改；当您需要我们提供与您账号或订单相关的客户服务时，我们可能会查询您的相关订单信息以便给予您适当的帮助和处理。<br />' +
    '为提升您的购物体验，您还可以授权我们访问您的相机、照片、麦克风、通讯录及您的位置信息，以实现我们对这些附加功能所涉及的信息的收集和使用。如我们会基于位置信息向您提供个性化的推荐，或基于该类授权权限向您提供更个性化的登陆、咨询、互动、信息分享或发布等功能。如您不提供该授权，不影响您对UitaUita服务基本功能的使用。当您需要关闭该功能时，大多数移动设备都会支持您的这项需求，具体方法请参考或联系您移动设备的服务商或生产商。<br />' +
    '为便于您对您的订单进行管理，我们会收集您的订单信息向您展示；我们也会使用您的订单信息来进行身份核验、确定交易、支付结算、完成配送、订单查询、提供客服咨询与售后服务或用来判断您的交易是否存在异常以便保护您的交易安全。<br />' +
    '您在UitaUita上生成的订单中，将可能包含您的身份信息、联络信息、支付信息、个人健康生理信息等，这些都属于敏感信息，请您谨慎向他人展示或对外提供。我们也将会在展示订单信息时，在保证信息可用性的基础上尽量做去标识化处理（如对订单中收件人的手机号进行脱敏处理）。<br />' +
    '<br />' +
    '2、我们在您使用服务过程中可能收集的信息<br />' +
    '为向您提供更契合您需求的页面展示和搜索结果、了解产品适配性、识别账号异常状态，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：<br />' +
    '日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录等。<br />' +
    '设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信息）、设备所在位置相关信息（例如IP 地址、GPS位置以及能够提供相关信息的WLAN接入点、蓝牙和基站等传感器信息）。<br />' +
    '请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。<br />' +
    '<br />' +
    '3、我们通过间接方式收集到的您的个人信息<br />' +
    '我们会从关联方、第三方合作伙伴获取您授权的相关信息。例如当您通过我们关联方、第三方合作伙伴网站及其移动应用软件等下单时，您向其提供的订单信息可能会转交给我们，以便我们处理您的订单并保证服务的顺利完成。<br />' +
    '我们将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的个人信息来源合法的前提下，收集并使用您的这些个人信息。<br />' +
    '<br />' +
    '（三）改善我们的产品或服务<br />' +
    '为改善我们的产品或服务、向您提供个性化的信息搜索及交易服务，我们会根据您的浏览及搜索记录、设备信息、位置信息、订单信息，提取您的浏览、搜索偏好、行为习惯、位置信息等特征，基于特征标签进行间接人群画像并通过生成定制页面、推送消息通知、发送邮件、发送短信等方式向您展示、推送信息。<br />' +
    '<br />' +
    '（四）为您提供安全保障<br />' +
    '为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或UitaUita相关协议规则的情况， 我们可能使用或整合您的个人信息、交易信息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。<br />' +
    '<br />' +
    '（五）其他用途<br />' +
    '我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意（确认同意的方式：如勾选、弹窗、站内信、邮件、短信等方式）。<br />' +
    '<br />' +
    '（六）征得授权同意的例外<br />' +
    '根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：<br />' +
    '1、与国家安全、国防安全有关的；<br />' +
    '2、与公共安全、公共卫生、重大公共利益有关的；<br />' +
    '3、与犯罪侦查、起诉、审判和判决执行等有关的；<br />' +
    '4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；<br />' +
    '5、所收集的个人信息是您自行向社会公众公开的；<br />' +
    '6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br />' +
    '7、根据您的要求签订合同所必需的；<br />' +
    '8、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；<br />' +
    '9、为合法的新闻报道所必需的；<br />' +
    '10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<br />' +
    '11、法律法规规定的其他情形。<br />' +
    '<br />' +
    '二、我们如何保存您的信息及个人信息跨境传输<br />' +
    '（一）您的个人信息我们将保存至您账号注销之后的十五天，除非延长保留期征得您的同意或法律法规另有规定。当您的个人信息超出我们所保存的期限后，我们会对您的个人信息进行删除或匿名化处理。<br />' +
    '<br />' +
    '（二）如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。<br />' +
    '<br />' +
    '（三）我们在中华人民共和国境内运营中收集和产生的信息，存储在中国境内。以下情形除外：<br />' +
    '1、法律法规有明确规定；<br />' +
    '2、获得您的授权同意；<br />' +
    '3、您使用UitaUita跨境交易服务，且需要向境外传输您的个人信息完成交易的；<br />' +
    '针对以上情形，我们会确保依据本政策及国家法律法规要求对您的个人信息提供足够的保护。<br />' +
    '<br />' +
    '三、我们如何使用 Cookie 和同类技术<br />' +
    '（一）Cookie<br />' +
    '为确保网站正常运转、为您提供更个性化的用户体验和服务，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储您的偏好或购物车内的商品等数据。<br />' +
    '我们不会将 Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。<br />' +
    '<br />' +
    '（二）网站信标和像素标签<br />' +
    '除 Cookie 外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。<br />' +
    '<br />' +
    '四、我们如何共享、转让、公开披露您的个人信息<br />' +
    '（一）共享<br />' +
    '我们不会与UitaUita以外的公司、组织和个人共享您的个人信息，但以下情况除外：<br />' +
    '1、在获取您授权同意的情况下共享：获得您授权同意后，我们会与其他方共享您的个人信息。<br />' +
    '2、在法定情形下共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。<br />' +
    '3、只有共享您的信息，才能实现UitaUita服务基本功能或提供您需要的服务。<br />' +
    '4、与我们的关联方共享： 我们可能会将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本政策中所声明目的的约束。<br />' +
    '5、与授权合作伙伴共享： 仅为实现本政策中声明的目的，我们可能会与授权合作伙伴共享您的订单信息、账户信息、设备信息以及位置信息，以提供更好的客户服务和用户体验。例如，在您上网购买我们的商品时，我们必须与物流服务提供商共享您的个人信息才能安排送货，或者安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。 我们的授权合作伙伴无权将共享的个人信息用于与UitaUita服务无关的其他用途。<br />' +
    '<br />' +
    '（二）转让<br />' +
    '我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：<br />' +
    '1、在获取授权同意的情况下转让：获得您的授权同意后，我们会向其他方转让您的个人信息；<br />' +
    '2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。<br />' +
    '<br />' +
    '（三）披露<br />' +
    '我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：<br />' +
    '1、获得您授权同意或基于您的主动选择，我们可能会披露您的个人信息；<br />' +
    '2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。<br />' +
    '<br />' +
    '（四）共享、转让、披露个人信息时事先征得授权同意的例外<br />' +
    '以下情形中，共享、转让、披露您的个人信息无需事先征得您的授权同意：<br />' +
    '1、与国家安全、国防安全有关的；<br />' +
    '2、与公共安全、公共卫生、重大公共利益有关的；<br />' +
    '3、与犯罪侦查、起诉、审判和判决执行等有关的；<br />' +
    '4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br />' +
    '5、您自行向社会公众公开的个人信息；<br />' +
    '6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。 根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。<br />' +
    '<br />' +
    '五、我们如何保护您的个人信息安全<br />' +
    '我们非常重视个人信息安全，安全团队会采取一切合理可行的措施，保护您的个人信息。<br />' +
    '（一）数据安全技术措施<br />' +
    '我们会采用符合业界标准、合理可行的安全防护措施保护您提供的个人信息，防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。<br />' +
    '1、我们会采用加密技术确保数据的保密性。例如，在您的浏览器与服务器之间交换数据（如信用卡信息）时受 SSL（Secure Socket Layer）加密保护。<br />' +
    '2、我们会采用数据脱敏技术增强个人信息在使用中安全性。<br />' +
    '3、我们对UitaUita网站提供HTTPS（Hyper Text Transfer Protocol over Secure Socket Layer）安全浏览方式。<br />' +
    '4、我们会采用受信赖的保护机制防止数据遭到恶意攻击。<br />' +
    '5、我们会采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。<br />' +
    '<br />' +
    '（二）其他安全措施<br />' +
    '1、我们通过建立数据分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。<br />' +
    '2、我们会部署访问控制机制，确保只有授权人员才可访问您的个人信息。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与我们的合作关系。<br />' +
    '3、我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。<br />' +
    '<br />' +
    '（三）我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。<br />' +
    '<br />' +
    '（四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。<br />' +
    '<br />' +
    '（五）安全提示<br />' +
    '1、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密， 我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。<br />' +
    '2、在使用UitaUita服务与第三方进行沟通或购买商品及服务时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。<br />' +
    '3 、请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评价或种草社区评论时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。<br />' +
    '4、请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入UitaUita服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。<br />' +
    '<br />' +
    '（六）安全事件处置。<br />' +
    '在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们会及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。<br />' +
    '<br />' +
    '（七）如果您对我们的个人信息保护有任何疑问，可通过本政策最下方约定的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本政策最下方【如何联系我们】约定的联系方式联络我们，以便我们采取相应措施。<br />' +
    '<br />' +
    '六、您如何管理您的个人信息<br />' +
    '我们鼓励您更新和修改您的信息以使其更准确有效，也请您理解， 您更正、删除、撤回授权或注销账号的决定，并不影响我们此前基于您的授权而开展的个人信息处理。除法律法规另有规定，当您更正、删除您的个人信息或申请注销账号时，我们可能不会立即从备份系统中更正或删除相应的信息，但会在备份更新时更正或删除这些信息。<br />' +
    '<br />' +
    '您可以通过以下方式来管理您的个人信息：<br />' +
    '（一）访问、更正您的个人信息<br />' +
    '您有权访问、更正您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问、更正您的个人信息：<br />' +
    '1、账户信息：如果您希望访问或编辑您的账户中的个人基本信息（如地址信息等），您可以在“我的”执行此类操作。<br />' +
    '2、订单信息：您可以通过访问“我的订单”页面查看您的所有订单状态；<br />' +
    '如果您无法通过上述路径访问、更正该等个人信息，您可以通过我们的在线客服与我们取得联系。对于您在使用UitaUita服务过程中产生的其他个人信息需要访问或更正，我们会根据本政策所列明的方式、期限及范围来响应您的请求。<br />' +
    '<br />' +
    '（二）删除您的个人信息<br />' +
    '您可以通过“（一）访问、更正您的个人信息”中列明的方式自主删除您的部分个人信息。在部分信息删除时，我们可能会要求您进行身份验证，以保障信息安全。<br />' +
    '在以下情形中，您可以向我们提出删除个人信息的请求：<br />' +
    '1、如果我们处理个人信息的行为违反法律法规；<br />' +
    '2、如果我们收集、使用您的个人信息，却未征得您的授权同意；<br />' +
    '3、如果我们处理个人信息的行为严重违反了与您的约定；<br />' +
    '4、如果我们不再为您提供UitaUita服务。<br />' +
    '若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。<br />' +
    '<br />' +
    '（三）改变您授权同意的范围或撤回您的授权<br />' +
    '请您理解，有些基本的个人信息是您使用UitaUita服务所必需的，但大多数其他个人信息的提供是由您决定的。您可以通过删除信息、关闭设备功能等方式改变您授权我们继续调用附加功能收集您个人信息的范围或撤回您的授权。当您撤回授权后，我们将无法继续为您提供您撤回授权所对应的服务，也不再处理您相应的个人信息。<br />' +
    '<br />' +
    '如果您不想接受我们给您发送的促销信息，您随时可通过以下方式取消：<br />' +
    '1、您可以根据短信退订指引要求我们停止发送促销短信。<br />' +
    '（四）UitaUita账户注销<br />' +
    '您可以联系客服提交“注销账户”申请。在您的UitaUita账户注销之后，我们将停止为您提供UitaUita服务，并依据您的要求，删除或匿名化您的个人信息，法律法规另有规定的除外。<br />' +
    '<br />' +
    '（五）约束信息系统自动决策<br />' +
    '在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害UitaUita商业秘密或其他用户权益、社会公共利益的前提下提供适当的救济方式。<br />' +
    '<br />' +
    '（六）响应您的上述请求<br />' +
    '为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。<br />' +
    '<br />' +
    '一般情况下，我们将在三十天内做出答复。如您不满意，还可以通过本政策下方提供的联系方式与我们联系。<br />' +
    '对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。<br />' +
    '<br />' +
    '在以下情形中，按照法律法规要求，我们将无法响应您的请求：<br />' +
    '1、与国家安全、国防安全有关的；<br />' +
    '2、与公共安全、公共卫生、重大公共利益有关的；<br />' +
    '3、与犯罪侦查、起诉、审判和判决执行等有关的；<br />' +
    '4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；<br />' +
    '5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；<br />' +
    '6、涉及商业秘密的。<br />' +
    '<br />' +
    '七、第三方服务<br />' +
    'UitaUita服务可能链接至第三方提供的社交媒体或其他服务（包括网站或其他服务形式）。包括：<br />' +
    '1、您可利用“分享”键将某些内容分享到UitaUita服务。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的电脑装置COOKIES，从而正常运行上述功能；<br />' +
    '2、我们通过广告或我们服务的其他方式向您提供链接，使您可以接入第三方的服务或网站；<br />' +
    '3、其他接入第三方服务的情形。<br />' +
    '该等第三方社交媒体或其他服务由相关的第三方负责运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本政策）约束，您需要仔细阅读其条款。本政策仅适用于我们所收集的任何信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，而我们对任何第三方使用由您提供的信息不承担任何责任。<br />' +
    '<br />' +
    '八、未成年人信息的保护<br />' +
    '我们建议： 任何未成年人参加网上活动应事先取得家长或其法定监护人（以下简称"监护人"）的同意。我们将根据国家相关法律法规的规定保护未成年人的相关信息。<br />' +
    '<br />' +
    '九、通知和修订<br />' +
    '（一）我们可能适时修改本政策的条款，该等修改构成本政策的一部分。我们会通过我们网站、移动端或其他官方路径发出更新版本或以其他适当方式提醒您相关内容的更新。如您仍然继续使用UitaUita服务的，即表示同意受经修订的本政策的约束。<br />' +
    '（二）我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。<br />' +
    '<br />' +
    '十、如何联系我们<br />' +
    '如您对本政策或您个人信息的相关事宜有任何疑问、意见或建议，您可通过访问我们的在线客服系统与我们联系，或通过邮件 ask@UitaUita.com 联系我们。<br />' +
    '<br />'

export  default  str

let str2 = `
<p>请您做检测前，仔细阅读以下内容:</p>
<h4>一、关于二手烟暴露检测</h4>
<p>1. 二手烟暴露评估检测的是尿液中的可替宁。可替宁是广泛应用于近期烟草产品使用和暴露的评估指标，包括二手烟接触
情况。可替宁是尼古丁的代谢产物，而尼古J是烟草制品和烟草烟雾中发现的活性药物，被动吸烟吸入的烟草烟雾是非吸
烟者接触尼古丁的主要来源。烟草烟雾中传递的尼古丁中多达90%可以被吸入身体中。</p>
<p>2.该检测服务仅用于非医疗用途。</p>
<p>3.在满足下列要求后，本公司方可进行检测:</p>
<p>(1)受检者已按要求填写自助检验单并已签署知情同意书;</p>
<p>(2) 所采集的样本符合要求。</p>
<p>3.收到样本后-般会在7个工作日内完成检测并出具报告,特殊情况可能需要更长时间(如样本不符合要求需要重新采样等)。</p>
<p>4.受检者应对样本真实性(即样本是否属于受检者本人)负责。</p>
<h4>二、隐私声明</h4>
<p>我们承诺对受检者个人信息与检测结果给予严格保护，检测结果和报告仅会通过受检者指定的方式告知。除非得到本人确
认，不会透露给第三方，但受检者知悉并许可我们在隐去受检者姓名等隐私信息后，在法律允许的框架内，可在报告解
读、科研、教学及质量控制中合理使用受检者检测数据。</p>
<h4>三、申请人承诺与声明.</h4>
<p>1. 我已阅读了以上内容，并了解二手烟暴露检测的相关信息;</p>
<p>2.我授权检测机构对检测涉及的样本进行处理;</p>
<p>3.我自愿并同意将自己样本的检测结果用于本人检测报告单的制作;</p>
<p>4.我已知晓该技术服务仅用于评估二手烟接触情况，我自愿进行此检测并同意签字。</p>
<p>本检测只对送检样品负责。“晓飞检”对检测结果保留最终解释权，如有疑义请在收到结果后的3个工作日内与我们联系。</p>


<h2>知情同意书</h2>
<p>请您做基因检测前，仔细阅读以下内容:</p>
<h4>一、关于基因检测的信息</h4>
<p>1.检测目的是检测DNA变异，评估个体用药风险、患病风险或健康状况;</p>
<p>2.依据科学报道，并鉴于基因的复杂性及科研水平局限性，检测的基因并不一-定包含所有相关基因，基因检测并不能检测
所有突变位点并提供完整的遗传信息;同时，限于目前的临床及科研水平，并不是所有与疾病发生、诊断、治疗相关的基
因都被临床所证实;</p>
<p>3.该检测需要高质量的DNA,如果样本体积、质量或初始样本的其他条件不能满足检测要求，可能需要重新采集样本;</p>
<p>4.该检测服务仅用于评估个体用药风险程度、患病风险程度或健康状况，而非诊断;</p>
<p>5.本公司承诺对受检者所有信息进行保密，包括个人信息和检测结果信息;</p>
<p></p>6.满足下列要求后，本公司方可进行检测:</p>
<p>(1) 受检者已按要求填写检测申请单并已签署知情同意书;</p>
<p>(2) 采集样本严格按照标准流程进行操作。</p>
<p>7.在收到样本后-般会在7个工作日内完成检测并出具报告，特殊情况可能需要更长时间(如样本不符合标准需要重新采祥等)</p>
<p>8.在检测过程中及知悉检测结果后引起不同程度的精神压力和负担，本公司不承担任何责任;</p>
<p>9.受检者应对样本真实性(即样本是否属于受检者本人)负责。</p>
<h4>二、隐私声明</h4>
<p>我们承诺对受检者个人信息与遗传信息给予严格保护，检测结果和报告仅会通过受检者指定的方式告知。除非得到本人确
认，不会透露给第三方，但受检者知悉并许可我们在隐去受检者姓名等隐私信息后，在法律允许的框架内，在报告解读、
科研、教学及质量控制中合理使用受检者检测数据。</p>
<h4>三、申请人承诺与声明</h4>
<p>1.我已阅读了以上内容，并了解基因检测可能会带来的精神压力;</p>
<p>2.我授权检测机构对检测涉及的样本进行处理;</p>
<p>3.我有机会提问而且所有相关问题均已得到解答，并对解答无异议;</p>
<p>4.我自愿并同意将自己样本的基因检测结果用于本人基因检测报告单的制作;</p>
<p>5.我已知晓该技术服务仅用于评估个体用药风险、患病风险或健康状况，而非诊断,我自愿进行此基因检测。</p>`

export {
    str2
}